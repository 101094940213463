<template>
	<div class="content">
		<WithLeftMenu :blocks="withLeftMenuContent" :class="'type2'"></WithLeftMenu>
		<section class="fullPageSectionContainer setMenuColor newForm" data-menu-color="light">
			<div class="fullPageSection">
				<div class="fullPageSection--back" style="background-color: #262E39"></div>
				<Feedback :type='2'></Feedback>
			</div>
		</section>
	</div>
</template>

<script>
	import	WithLeftMenu from '@/components/app/WithLeftMenu'

	import 	projectsData from "@/assets/json/projects.json";

	export default {
		metaInfo: {
			title: 'Создание сайтов на 1С-Битрикс, цены и примеры работ | «‎Артрокетс»',
			meta: [
				{ vmid: 'description', name: 'description', content: 'Разработка сайтов любой сложности на 1С-Битрикс. Наши проекты на CMS Bitrix, преимущества системы и стоимость разработки. Заполните форму на сайте, и мы вам перезвоним!' },
				{ vmid: 'og:title', property: 'og:title', content: 'Создание сайтов на 1С-Битрикс, цены и примеры работ | «‎Артрокетс»' },
				{ vmid: 'og:description', property: 'og:description', content: 'Разработка сайтов любой сложности на 1С-Битрикс. Наши проекты на CMS Bitrix, преимущества системы и стоимость разработки. Заполните форму на сайте, и мы вам перезвоним!' },
			],
		},
		data: () => ({
			withLeftMenuContent: [
				{
					component: 'projects',
					name: 'Наши проекты на CMS Bitrix',
					quantity: 3,
					quantityTable: 3,
					quantityMobile: 3,
					showPagination: false,
					data: projectsData,
					cms: "bitrix",
					smallElements: true
				},{
					component: 'advantages',
					name: 'Преимущества 1С-Битрикс',
					columns: 3,
					sliderInMobile: true,
					data: [
						{
							title: 'Универсальность',
							text: 'На платформе возможно создать проект любой сложности: от лендинга до крупного портала'
						},{
							title: 'Простота в управлении',
							text: 'Система позволяет публиковать информацию, проверять списки заказов, делать почтовую рассылку и многое другое без помощи программистов.'
						},{
							title: 'Высокая степень защиты',
							text: 'Включает антивирус, сканер безопасности, и другие средства защиты от DDoS-атак и взломов злоумышленников'
						},{
							title: 'Техническая поддержка',
							text: 'Разработчики платформы постоянно выпускают обновления и работают с обращениями владельцев сайтов'
						},{
							title: 'Встроенная интеграция со многими сервисами',
							text: 'службы доставки, CRM-системы, онлайн-кассы, онлайн-маркеты, агрегаторы и так далее.'
						},{
							title: 'Надежность и производительность',
							text: 'Выдерживает высокие нагрузки, работает стабильно и без багов.'
						}
					]
				},{
					component: 'advantages',
					name: 'Стоимость разработки сайта на 1С-Битрикс',
					columns: 3,
					sliderInMobile: false,
					data: [
						{
							title: 'Корпоративный сайт',
							text: 'Для позиционирования и продвижения бренда в интернете',
							bottomText: 'от 500 000 руб.',
							link: '/uslugi/korp-site/'
						},{
							title: 'Интернет-магазин',
							text: 'Каталог услуг или товаров с возможностью покупки',
							bottomText: 'от 600 000 руб.',
							link: '/uslugi/internet-magazin/'
						},{
							title: 'Перенос сайта на<br> 1С-Битрикс',
							text: 'Перенос проекта с другой платформы',
							bottomText: 'от 400 000 руб.',
						}
					]
				},{
					component: 'contentBlock',
					name: 'Уникальные возможности сайта на Битрикс',
					data: [
						{
							component: 'advantages',
							additionalClass: 'margin4',
							columns: 1,
							sliderInMobile: false,
							data: [
								{
									title: 'Композитный сайт',
									text: 'Композитный сайт — это технология ускорения загрузки страниц в 100 раз. Сайт загружается мгновенно для пользователей. Любой проект на основе Битрикс может стать композитным.',
								}
							]
						},{
							component: 'youtubeVideo',
							additionalClass: 'margin4',
							data: {
								videoCode: 'jo4A4Wqlksc'
							}
						},{
							component: 'advantages',
							additionalClass: 'margin4',
							columns: 1,
							sliderInMobile: false,
							data: [
								{
									title: 'Интеграция с 1С',
									text: 'Битрикс — одна из самых дружелюбных систем для интеграций, со встроенным модулем обмена. Интеграция  с 1С позволяет вести двусторонний учет заказов, контрагентов, остатков и синхронизировать товарную номенклатуру.',
								},{
									title: 'Соответствие требованиям ФЗ-54 и ФЗ-152',
									text: 'Сайт не будет нарушать законы о защите персональных данных и применения контрольно-кассовой техники. Для этого на нем будут учтены согласие на обработку личной информации, автоматическая и ручная печать чеков и поддержка любого формата касс: физических, онлайн и 1С. '
								},{
									title: 'Обучение',
									text: 'У разработчиков системы налаженные процессы поддержки клиентов. На сайте <a href="https://www.1c-bitrix.ru" target="_blank" rel="nofollow" class="redText">https://www.1c-bitrix.ru</a> есть бесплатные онлайн уроки, вебинары, ответы на часто задаваемы вопросы и чат с техподдержкой, который работает с понедельника по пятницу с 9:00 до 20:00.'
								},{
									title: 'Битрикс: мобильное приложение',
									text: 'Для сайта, созданного на основе Битрикс, можно создать мобильное приложение для iOS и Android. '
								},{
									title: 'Маркетплейс готовых решений',
									text: 'На портале <a href="https://marketplace.1c-bitrix.ru" target="_blank" rel="nofollow" class="redText">https://marketplace.1c-bitrix.ru</a> есть большой выбор готовых модулей для сайта. Используя готовые решения, можно в среднем в 2-3 раза сократить бюджет и время на разработку.'
								},{
									title: 'Несколько вариантов лицензий',
									text: 'Лицензия Битрикс — это набор характеристик и функций, которые будут доступны для сайта. Чем дороже лицензия, тем больше опций она включает.'
								}
							]
						},{
							component: 'imageWithText',
							additionalClass: 'margin4',
							columns: 4,
							sliderInMobile: true,
							data: [
								{
									image: require(`@/assets/img/licenses/1.png`),
									title: 'Старт',
									text: '5 400 руб.',
									textColor: '#ED4A4A'
								},{
									image: require(`@/assets/img/licenses/2.png`),
									title: 'Стандарт',
									text: '15 900 руб.',
									textColor: '#ED4A4A'
								},{
									image: require(`@/assets/img/licenses/3.png`),
									title: 'Малый бизнес',
									text: '35 900 руб.',
									textColor: '#ED4A4A'
								},{
									image: require(`@/assets/img/licenses/4.png`),
									title: 'Бизнес',
									text: '72 900 руб.',
									textColor: '#ED4A4A'
								}
							]
						},{
							component: 'text',
							text: 'Мы поможем подобрать оптимальный пакет именно для вашего бизнеса!'
						}
					]
				},{
					component: 'contentBlock',
					name: 'Наш подход',
					background: '#262E39',
					additionalClass: 'whiteText noBorder',
					data: [
						{
							component: 'icon',
							additionalClass: 'margin7',
							size: '21px',
							svg: '<span class="icon-technorosst"><span class="path1"></span><span class="path2"></span></span>'
						},{
							component: 'text',
							text: 'Результаты нашей работы клиент ощущает сразу же: после редизайна интернет-магазина Техноросст, трафик у клиента вырос в 3 раза и количество продаж увеличилось в 10 раз',
							additionalClass: 'margin4',
						},{
							component: 'graph',
							data: {
								graphImage: require(`@/assets/img/bitrix/graph.png`),
								additionalClass: 'technorosst',
								labels: [
									{
										name: 'Июнь <span>2018</span>'
									},{
										name: 'Август <span>2018</span>',
										withLine: true,
										logo: '<span class="icon-logo"><span class="path1"></span><span class="path2"></span></span>'
									},{
										name: 'Октябрь <span>2018</span>'
									},{
										name: 'Декабрь <span>2018</span>'
									}
								]
							}
						}
					]
				},{
					component: 'photoScrollBlocks',
					name: 'Наши сертификаты',
					showQuantity: 3,
					clickable: true,
					data: [
						{
							title: 'Сертификаты студии',
							data: {
								images: [
									{
										image: require(`@/assets/img/certificates/studio/1.jpg`),
										fullImage: require(`@/assets/img/certificates/studio/1_full.jpg`),
										desc: 'Золотой сертифицированный партнер с 2017 года'
									},{
										image: require(`@/assets/img/certificates/studio/2.jpg`),
										fullImage: require(`@/assets/img/certificates/studio/2_full.jpg`),
										desc: 'В ТОП-10 лучших сертифицированных партнеров в г.Казань'
									},{
										image: require(`@/assets/img/certificates/studio/3.jpg`),
										fullImage: require(`@/assets/img/certificates/studio/3_full.jpg`),
									}
								]
							}
						},{
							title: 'Сертификаты сотрудников',
							secondTitle: 'Султан Вербин',
							secondDesc: 'Backend разработчик. Опыт работы с Битриксом 5 лет',
							data: {
								images: [
									{
										image: require(`@/assets/img/certificates/sultan/1.jpg`),
										fullImage: require(`@/assets/img/certificates/sultan/1_full.jpg`),
									},{
										image: require(`@/assets/img/certificates/sultan/2.jpg`),
										fullImage: require(`@/assets/img/certificates/sultan/2_full.jpg`),
									},{
										image: require(`@/assets/img/certificates/sultan/3.jpg`),
										fullImage: require(`@/assets/img/certificates/sultan/3_full.jpg`),
									},{
										image: require(`@/assets/img/certificates/sultan/4.jpg`),
										fullImage: require(`@/assets/img/certificates/sultan/4_full.jpg`),
									},{
										image: require(`@/assets/img/certificates/sultan/5.jpg`),
										fullImage: require(`@/assets/img/certificates/sultan/5_full.jpg`),
									},{
										image: require(`@/assets/img/certificates/sultan/6.jpg`),
										fullImage: require(`@/assets/img/certificates/sultan/6_full.jpg`),
									},{
										image: require(`@/assets/img/certificates/sultan/7.jpg`),
										fullImage: require(`@/assets/img/certificates/sultan/7_full.jpg`),
									},{
										image: require(`@/assets/img/certificates/sultan/8.jpg`),
										fullImage: require(`@/assets/img/certificates/sultan/8_full.jpg`),
									}
								]
							}
						},{
							secondTitle: 'Булат Давлетшин',
							secondDesc: 'Технический директор. Опыт работы с Битриксом 8 лет',
							data: {
								images: [
									{
										image: require(`@/assets/img/certificates/bulat/1.jpg`),
										fullImage: require(`@/assets/img/certificates/bulat/1_full.jpg`),
									},{
										image: require(`@/assets/img/certificates/bulat/2.jpg`),
										fullImage: require(`@/assets/img/certificates/bulat/2_full.jpg`),
									},{
										image: require(`@/assets/img/certificates/bulat/3.jpg`),
										fullImage: require(`@/assets/img/certificates/bulat/3_full.jpg`),
									},{
										image: require(`@/assets/img/certificates/bulat/4.jpg`),
										fullImage: require(`@/assets/img/certificates/bulat/4_full.jpg`),
									},{
										image: require(`@/assets/img/certificates/bulat/5.jpg`),
										fullImage: require(`@/assets/img/certificates/bulat/5_full.jpg`),
									},{
										image: require(`@/assets/img/certificates/bulat/6.jpg`),
										fullImage: require(`@/assets/img/certificates/bulat/6_full.jpg`),
									}
								]
							}
						},{
							secondTitle: 'Руслан Закиров',
							secondDesc: 'Fullstack разработчик. Опыт работы с Битриксом 2 года',
							data: {
								images: [
									{
										image: require(`@/assets/img/certificates/ruslan/1.jpg`),
										fullImage: require(`@/assets/img/certificates/ruslan/1_full.jpg`),
									},{
										image: require(`@/assets/img/certificates/ruslan/2.jpg`),
										fullImage: require(`@/assets/img/certificates/ruslan/2_full.jpg`),
									}
								]
							}
						},{
							secondTitle: 'Айрат Вагапов',
							secondDesc: 'Менеджер проектов. Опыт работы с Битриксом 3 года',
							data: {
								images: [
									{
										image: require(`@/assets/img/certificates/ayrat/1.jpg`),
										fullImage: require(`@/assets/img/certificates/ayrat/1_full.jpg`)
									}
								]
							}
						},{
							secondTitle: 'Айгуль Кашапова',
							secondDesc: 'Руководитель отдела SEO. Опыт работы с Битриксом 3 года',
							data: {
								images: [
									{
										image: require(`@/assets/img/certificates/aygul/1.jpg`),
										fullImage: require(`@/assets/img/certificates/aygul/1_full.jpg`),
									}
								]
							}
						},{
							secondTitle: 'Алексей Денисов',
							secondDesc: 'Менеджер проектов. Опыт работы с Битриксом 4 года',
							data: {
								images: [
									{
										image: require(`@/assets/img/certificates/lexa/1.jpg`),
										fullImage: require(`@/assets/img/certificates/lexa/1_full.jpg`),
									}
								]
							}
						}
					]
				}
			]
		}),
		name: 'bitrixs',
		components: {
			Feedback: () => import('@/components/new/Forms/Feedback'),
			WithLeftMenu,
		}
	}
</script>
